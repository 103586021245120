import { getMarketingData } from '@nubank/www-latam-commons/utils/marketingUtils';
import getPersona from 'tracking/navegg/getPersona';
import { trackApplication } from 'tracking/application/registration';
import {
  trackRTRStart,
  trackRTRApproval,
  trackRTRDenial,
} from 'tracking/application/rtr';
import { sentryException } from 'services/errorTracking/sentryException';

import { PHONE_REMINDER } from '../../utils/experiments';

import setReturningApplicant from './core/setReturningApplicant';
import startRealTimeAnalysis from './core/startRealTimeAnalysis';
import applicationRepository from './repository/application';
import realTimeAnalysisRepository from './repository/realTimeAnalysis';
import discoveryRepository from './repository/discovery';

export function createRegisterProspect(core, repository) {
  return async ({
    prospect,
    prospectType,
    prospectTypeTracking,
    fromInviter,
    onThrottledResponse,
    onAnalysisStart,
    onAnalysisFinish,
    onApplicationFinish,
  }) => {
    const additionalParameters = {
      ...PHONE_REMINDER.getPhoneParametersToETL(),
    };
    const typeOfProspectType = prospectType.type;
    const prospectTypeTrackingType = prospectTypeTracking
      ? prospectTypeTracking.type
      : typeOfProspectType;

    try {
      additionalParameters.navegg_profile = await repository.getPersona();
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'get_navegg_persona',
        namespace: 'createRegisterProspect()',
      });
    }

    const payload = {
      prospect,
      marketing: repository.getMarketingData({ additionalParameters }),
      'accepted-contracts': [
        {
          name: 'politica_privacidade_2020_08_10',
        },
      ],
    };

    const registerUrl = await repository.getRegistrationURL(prospectType, fromInviter);

    const {
      marketingId,
      hasThrottle,
      hasRealtimeAnalysis,
      realtimeUrl,
    } = await repository.sendProspectApplication(registerUrl, payload);

    if (hasThrottle) {
      onThrottledResponse();
      return {};
    }

    repository.trackApplication({
      prospectType: prospectTypeTrackingType,
      marketingId,
    });

    core.setReturningApplicant(typeOfProspectType);

    if (hasRealtimeAnalysis) {
      onAnalysisStart();

      repository.trackRTRStart({
        prospectType: prospectTypeTrackingType,
        marketingId,
      });

      const pollingConfig = await repository.getPollingConfig(realtimeUrl);

      if (pollingConfig) {
        let approvedProducts;
        let analysisResult;

        if (pollingConfig.hasTemplate) {
          approvedProducts = pollingConfig?.template?.id;
        } else {
          analysisResult = await core.startRealTimeAnalysis({
            url: realtimeUrl,
            pollingConfig,
          });

          approvedProducts = analysisResult?.template?.id;
        }

        const trackingFn = approvedProducts ? 'trackRTRApproval' : 'trackRTRDenial';
        repository[trackingFn]({
          prospectType: prospectTypeTrackingType,
          marketingId,
          approvedProducts,
        });

        onAnalysisFinish({
          analysisResult: analysisResult || pollingConfig,
          prospect: {
            prospectType: typeOfProspectType,
            marketingId,
          },
        });

        return { marketingId };
      }

      onAnalysisFinish(false);

      return { marketingId };
    }

    onApplicationFinish(false);

    return { marketingId };
  };
}

export default createRegisterProspect(
  {
    startRealTimeAnalysis,
    setReturningApplicant,
  },
  {
    ...applicationRepository,
    ...realTimeAnalysisRepository,
    ...discoveryRepository,
    getMarketingData,
    getPersona,
    trackApplication,
    trackRTRStart,
    trackRTRApproval,
    trackRTRDenial,
  },
);
