import getClient from './getClient';

const NAVEGG_TIMEOUT = 1000;

function naveggStore() {
  function init() {
    window.naveggReady = window.naveggReady || [];
  }

  function get() {
    return window.naveggReady;
  }

  return {
    init,
    get,
  };
}

export default function getPersona(client = getClient(), store = naveggStore()) {
  return new Promise((resolve, reject) => {
    store.init();
    const timeoutID = setTimeout(() => {
      resolve({});
    }, NAVEGG_TIMEOUT);
    store.get().push(() => {
      try {
        const naveggClient = client ?? getClient();
        const { seg: segments } = naveggClient;

        const persona = segments.reduce((acc, segmentKey) => {
          const segmentValue = naveggClient.getSegment(segmentKey);

          return {
            ...acc,
            [segmentKey]: segmentValue,
          };
        }, {});
        clearTimeout(timeoutID);
        resolve(persona);
      } catch (error) {
        reject(new Error(`Failed to getPersona ${error}`));
      }
    });
  });
}
