import * as Sentry from '@sentry/react';

export const ERROR_SEVERITY = {
  DEFAULT: 'Error',
  CRITICAL: 'Critical',
};

export function sentryException({
  error,
  flow,
  checkpoint,
  namespace,
  level = ERROR_SEVERITY.DEFAULT,
  extraDetails,
}) {
  return (
    Sentry.captureException(error, scope => {
      scope.setTag('flow', flow);
      scope.setTag('checkpoint', checkpoint);
      scope.setTag('namespace', namespace);
      scope.setLevel(Sentry.Severity[level]);
      scope.setFingerprint([
        flow,
        checkpoint,
        namespace,
        process.env.NODE_ENV,
        error?.message,
      ]);
      if (extraDetails) {
        Object.keys(extraDetails).forEach(key => {
          scope.setExtra(key, extraDetails[key]);
        });
      }
    })
  );
}
