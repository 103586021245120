/* eslint-disable no-underscore-dangle */
import checkMatchingProperties from '@nubank/nuds-web/utils/object/checkMatchingProperties';

import isEmpty from '@nubank/www-latam-commons/utils/object/isEmpty';
import { fetchJson, isNetworkError } from '@nubank/www-latam-commons/utils/http';
import { sentryException } from 'services/errorTracking/sentryException';

const normalizeFieldsNames = field => field.split(':').pop().trim().replace(/-/, '_');

const getProspectErrorFields = jsonErrorString => {
  try {
    const objectError = JSON.parse(jsonErrorString);
    const {
      error: { prospect: prospectErrors },
    } = objectError;

    const regexGetFields = /(:.*?\s)/gi;
    const errorFields = prospectErrors.match(regexGetFields);

    return errorFields.map(normalizeFieldsNames);
  } catch (error) {
    sentryException({
      error,
      flow: 'application_flow',
      checkpoint: 'get_prospect_error_fields',
      namespace: 'createApplicationRepository()',
    });

    return [];
  }
};

export function createApplicationRepository(fetch) {
  return {
    async sendProspectApplication(registrationURL, payload) {
      try {
        const response = await fetch(registrationURL, {
          method: 'POST',
          body: payload,
        });

        const hasThrottle = Boolean(
          response.error
          && response.error.toLowerCase
          && response.error.toLowerCase() === 'ok',
        );

        const hasRealtimeAnalysis = Boolean(
          response._links && (response._links.analysis || response._links.approved_products),
        );

        const link = hasRealtimeAnalysis ? (response._links.analysis || response._links.approved_products).href : '';

        return {
          marketingId: response.marketing_id,
          hasThrottle,
          hasRealtimeAnalysis,
          realtimeUrl: link,
        };
      } catch (error) {
        const originalError = {
          message: error.message,
          stack: error.stack,
        };

        if (isNetworkError(error)) {
          const responseErrors = {
            generic: true,
            originalError,
          };
          throw responseErrors;
        }

        const errorResponseText = await error.response.text();
        const listFieldsProspectErrors = getProspectErrorFields(errorResponseText);
        const responseErrors = checkMatchingProperties(payload.prospect, listFieldsProspectErrors);

        if (isEmpty(responseErrors)) {
          responseErrors.generic = true;
          responseErrors.originalError = originalError;
        }

        throw responseErrors;
      }
    },
  };
}

export default createApplicationRepository(fetchJson);
